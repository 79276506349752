import { cloneDeep, keyBy } from 'lodash-es';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';

import { useAuthStore } from '~/auth/stores/auth.store';
import { useCustomViewStore } from '~/common/stores/custom-view.store.js';
import { useDashboardStore } from '~/dashboard/store/dashboard.store.js';
import { useDashboardFormsStore } from '~/dashboard/store/dashboard-forms.store.js';
import { getFields, useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store.js';
import { useFamConstants } from '~/forms-as-module/composables/fam-constants.composable.js';

export function useFamViewStore(id = 'fam-view', meta = {}) {
  const auth_store = useAuthStore();
  const custom_view_store = useCustomViewStore();
  const dashboard_store = useDashboardStore();
  const dashboard_forms_store = useDashboardFormsStore();
  const form_template_detail_store = useFormTemplateDetailStore();

  const route = useRoute();
  const { DEFAULT_COLUMNS, DEFAULT_DISPLAY_FILTERS } = useFamConstants();

  function getSupportedTypeOptions(curr, acc) {
    curr.supported_types.forEach((type) => {
      if (acc[type])
        acc[type].push(curr);
    });
  }

  function getNonSupportedTypeOptions(curr, acc = null) {
    const default_value = { columns: [], filters: [], gallery_columns: [], gallery_filters: [] };
    if (!curr?.children?.length)
      return default_value;
    const supported_types = ['columns', 'filters', 'gallery_columns', 'gallery_filters'];

    if (curr?.children?.length) {
      const fields_with_supported_types = curr.children.filter(value => value.supported_types && value.type !== 'table');
      if (fields_with_supported_types.length) {
        const updated_data = fields_with_supported_types.reduce((accumulator, curr) => {
          getSupportedTypeOptions(curr, accumulator);
          return accumulator;
        }, default_value);

        if (!acc)
          return updated_data;
        supported_types.forEach((type) => {
          const updated_children = updated_data[type];
          if (updated_children?.length)
            acc[type].push({ ...curr, children: updated_children });
        });
      }

      else {
        if (!acc)
          return;
        const is_support_type_available = {};
        const updated_data = curr.children.reduce((accumulator, curr) => {
          const support_type_data = getNonSupportedTypeOptions(curr);
          accumulator[curr.uid] = support_type_data;
          supported_types.forEach((type) => {
            is_support_type_available[type] = is_support_type_available[type] || (support_type_data?.[type]?.length > 0);
          });
          return accumulator;
        }, {});
        supported_types.forEach((type) => {
          if (is_support_type_available[type]) {
            acc[type].push({
              ...curr,
              children: curr.children.map(section => ({
                ...section,
                ...(updated_data[section.uid][type].length
                  ? { children: updated_data[section.uid][type] }
                  : {}),
              })),
            });
          }
        });
      }
    }
  }

  return defineStore(id, {
    state: () => ({
      views: [],
      active_view_id: null,
    }),
    getters: {
      views_list() {
        if (this.$id.includes('dashboard'))
          return this.views || [];

        return custom_view_store.views.filter(view =>
          view.service === 'forms'
          && view.feature === meta.feature
          && view.resource_id === route.params.template_uid
          && view.resource_type === 'template',
        );
      },
      active_view() {
        return this.views_list?.find(item => item.uid === this.active_view_id);
      },
      display_filters() {
        let columns = [];
        if (!this.active_view?.data?.display_filters)
          columns = DEFAULT_DISPLAY_FILTERS;
        else columns = this.active_view?.data?.display_filters;

        return columns.reduce((fields, item) => {
          if (form_template_detail_store.field_filters_map[item.field])
            fields.push(form_template_detail_store.field_filters_map[item.field]);
          return fields;
        }, []);
      },
      active_view_columns() {
        let columns = [];
        if (!this.active_view?.data?.columns?.length)
          columns = DEFAULT_COLUMNS;
        else columns = this.active_view?.data?.columns;
        // mapping
        return columns.reduce((fields, item) => {
          if (form_template_detail_store.field_filters_map[item.field])
            fields.push({ ...form_template_detail_store.field_filters_map[item.field], size: item?.size || 300 });
          return fields;
        }, []);
      },
      active_view_conditional_formatting() {
        const filters = (this.active_view?.data?.conditional_formatting || []);
        return filters;
      },
      active_view_conditional_formatting_map() {
        return keyBy(this.active_view_conditional_formatting, 'uid');
      },
      template_columns() {
        return Object.values(form_template_detail_store.field_filters_map);
      },
      field_filters_tree() {
        return form_template_detail_store.field_filters_tree;
      },
      fields_supported_type_map() {
        return this.field_filters_tree.reduce((acc, curr) => {
          if (curr?.supported_types)
            getSupportedTypeOptions(curr, acc);
          else
            getNonSupportedTypeOptions(curr, acc);
          return acc;
        }, { columns: [], filters: [], gallery_columns: [], gallery_filters: [] });
      },
      filters_supported_fields_list() {
        return getFields(cloneDeep(this.fields_supported_type_map.filters));
      },
    },
    actions: {
      async setCustomViews() {
        await custom_view_store.set_custom_views({ query: {} }, { force_update: true });
      },
      setActiveViewID(uid) {
        if (uid)
          this.active_view_id = uid;
        else if (this.views_list?.length)
          this.active_view_id = this.views_list[0].uid;
      },
      getDefaultViewName() {
        if (this.views_list?.length)
          return null;
        return `All ${form_template_detail_store?.form_template_detail?.plural_name || form_template_detail_store?.form_template_detail?.name}`;
      },
      async createView(data) {
        data.data.name = data?.data?.name || this.getDefaultViewName();
        const payload = {
          ...data,
          name: data?.data?.name,
          service: 'forms',
          feature: meta.feature,
          resource_id: form_template_detail_store.form_template_detail?.uid,
          resource_type: 'template',
          asset: route.params.asset_id,
        };
        let res = null;
        if (this.$id.includes('dashboard_view'))
          await this.updateDashboardViews(payload);
        else if (this.$id.includes('widget_view'))
          res = this.updateWidgetViews('create', payload);
        else
          await custom_view_store.create_view(payload);
        if (res)
          this.setActiveViewID(res?.uid);
      },
      async updateView(data = {}) {
        const view_details = this.views_list.find(view => view.uid === data.uid);
        if (!view_details)
          return await this.createView(data);

        const payload = {
          ...view_details,
          name: data.name,
          data: {
            ...view_details.data,
            ...data.data,
          },
          order_index: data.order_index || view_details.order_index,
          created_by: auth_store.logged_in_user_id,
        };
        if (this.$id.includes('dashboard_view'))
          await this.updateDashboardViews(payload, view_details.uid);
        else if (this.$id.includes('widget_view'))
          this.updateWidgetViews('update', payload, view_details.uid);
        else
          await custom_view_store.update_view(payload, view_details.uid);
      },
      async deleteView(view_id) {
        if (this.$id.includes('widget_view'))
          this.updateWidgetViews('delete', null, view_id);
        else
          await custom_view_store.delete_view(view_id);
      },
      updateWidgetViews(action, data, view_id = null) {
        if (action === 'delete') {
          this.views = cloneDeep(this.views_list.filter(view => view.uid !== view_id));
          this.updateFormsConfiguration();
        }
        else {
          const payload = data;
          payload.uid = view_id || crypto.randomUUID();

          const view_index = this.views_list.findIndex(item => item.uid === payload.uid);
          if (view_index === -1) {
            this.views.push(payload);
          }
          else {
            this.views[view_index] = {
              ...this.views[view_index],
              ...payload,
              data: {
                ...this.views[view_index].data,
                ...payload.data,
              },
            };
          }
          this.updateFormsConfiguration();
          return payload;
        }
      },
      updateFormsConfiguration() {
        dashboard_forms_store.forms_configuration.tabs = cloneDeep(this.views_list);
        dashboard_store.set_widget_configuration({
          ...dashboard_forms_store.forms_configuration,
        });
      },
      async updateDashboardViews(data, view_id = null) {
        data.uid = view_id || crypto.randomUUID();
        data.name = dashboard_store.current_dashboard.name;
        dashboard_store.current_dashboard.meta = {
          ...dashboard_store.current_dashboard.meta,
          views: [data],
        };
        await dashboard_store.update_dashboard(dashboard_store.current_dashboard);
        this.views = cloneDeep([data]);
      },
    },
  })();
}
