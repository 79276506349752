<script setup>
import { find } from 'lodash-es';
import { Tippy } from 'vue-tippy';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useInventoryStore } from '~/inventory/store/inventory.store.js';

const props = defineProps({
  item: { type: Object, default: () => {} },
  itemProcurementDetails: { type: Object, default: () => {} },
});

const emit = defineEmits(['close', 'update']);

const { auth_store, $services, route } = useCommonImports();
const inventory_store = useInventoryStore();

const form$ = ref(null);
const state = reactive({
  form: {},
  is_loading: false,
});

function canModifyScope(warehouse_uid) {
  if (warehouse_uid)
    return inventory_store.warehouses_map?.[warehouse_uid]?.permissions?.modify_bom || false;
}

function onFormMounted(el$) {
  state.is_loading = true;
  const data = [];

  props?.itemProcurementDetails?.warehouses.forEach((warehouse) => {
    data.push({
      uid: warehouse.uid,
      ...(!warehouse?.instances?.length ? { scope: warehouse?.scope || 0 } : {}),
      name: warehouse?.name,
      instances: warehouse?.instances?.map(instance => ({
        instance_uid: instance.instance_uid,
        instance_name: instance.instance_name,
        qty: instance.qty || 0,
      })) || [],
    });
  });
  setTimeout(() => {
    el$.update({ warehouses: data });
  }, 0);
  state.is_loading = false;
}

function preventKeydown(e) {
  if ([189, 187, 69].includes(e.keyCode))
    e.preventDefault();
}

function onInputFocused(type, index, instance_index) {
  if (type === 'warehouse' && +state.form.warehouses[index].scope === 0) {
    state.form.warehouses[index].scope = null;
  }
  else if (type === 'instance' && +state.form.warehouses[index].instances[instance_index].qty === 0) {
    state.form.warehouses[index].instances[instance_index].qty = null;
  }
  form$.value.update(state.form);
}

function onInputBlurred(type, index, instance_index) {
  if (type === 'warehouse' && state.form.warehouses[index].scope === null) {
    state.form.warehouses[index].scope = 0;
  }
  else if (type === 'instance' && state.form.warehouses[index].instances[instance_index].qty === null) {
    state.form.warehouses[index].instances[instance_index].qty = 0;
  }
  form$.value.update(state.form);
}

function getWarehouseInstancesScope(warehouse_uid) {
  const warehouse = find(state?.form.warehouses, { uid: warehouse_uid });
  if (warehouse?.instances?.length) {
    return warehouse?.instances?.reduce((acc, curr) => acc + +curr.qty, 0);
  }
}

async function save() {
  try {
    const warehouses_data = state.form.warehouses.map(warehouse => ({
      ...warehouse,
      scope: +warehouse.scope || 0,
      instances: warehouse?.instances?.map(instance => ({ ...instance, qty: +instance.qty })) || [],
    }));
    const data = await $services.inventory_items.update_item_procurement_qty({
      asset_id: route.params.asset_id,
      body: { item_uid: props.item.uid, warehouses: warehouses_data },
    });
    if (data.status === 200) {
      emit('update');
    }
  }
  catch (error) {
    logger.log('🚀 ~ save ~ error:', error);
  }
}
</script>

<template>
  <HawkModalContainer :width="400">
    <Vueform
      ref="form$"
      v-model="state.form"
      size="sm"
      :display-errors="false"
      :columns="{ container: 12, label: 4, wrapper: 12 }"
      :format-load="(data) => data"
      :endpoint="save"
      @mounted="onFormMounted"
    >
      <div class="col-span-12">
        <HawkModalHeader @close="emit('close')">
          <template #title>
            {{ $t('Edit scope') }}
          </template>
          <template #subtitle>
            #{{ item.number }}
          </template>
        </HawkModalHeader>
        <HawkModalContent>
          <HawkLoader v-show="state.is_loading" container_class="" />
          <div class="grid gap-4" :class="{ invisible: state.is_loading }">
            <ListElement
              name="warehouses"
              :submit="false"
              :controls="{ add: false, remove: false }"
            >
              <template #default="{ index }">
                <ObjectElement
                  :name="index"
                >
                  <template v-if="state.form.warehouses?.[index]?.instances?.length">
                    <div class="flex justify-between col-span-12 text-gray-700 mt-4">
                      <div class="text-base font-semibold">
                        {{ state.form?.warehouses?.[index].name }}
                      </div>
                      <div class="text-sm font-medium text-gray-700">
                        {{ getWarehouseInstancesScope(state.form?.warehouses?.[index].uid) }}
                        {{ inventory_store.uom_map?.[item?.uom]?.symbol }}
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <TextElement
                      name="scope"
                      input-type="number"
                      :rules="['numeric', 'min:0', 'integer', 'nullable']"
                      autocomplete="off"
                      :default="0"
                      :disabled="!canModifyScope(state.form.warehouses?.[index]?.uid)"
                      @keydown="e => preventKeydown(e)"
                      @focus="e => onInputFocused('warehouse', index)"
                      @blur="e => onInputBlurred('warehouse', index)"
                    >
                      <template #label>
                        <Tippy v-if="state.form?.warehouses?.[index]?.name?.length > 25">
                          <div class="text-sm text-gray-700 font-semibold">
                            {{ $filters.truncate(state.form?.warehouses?.[index]?.name, 25) }}
                          </div>
                          <template #content>
                            <div class="break-all">
                              {{ state.form?.warehouses?.[index]?.name }}
                            </div>
                          </template>
                        </Tippy>
                        <div v-else class="text-sm text-gray-700 font-semibold">
                          {{ state.form?.warehouses?.[index]?.name }}
                        </div>
                      </template>
                    </TextElement>
                  </template>
                  <ListElement name="instances" :controls="{ add: false, remove: false }">
                    <template #default="{ index: instance_index }">
                      <ObjectElement :name="instance_index">
                        <TextElement
                          name="qty" input-type="number"
                          :rules="['numeric', 'min:0', 'integer', 'nullable']"
                          autocomplete="off"
                          force-numbers
                          :disabled="!canModifyScope(state.form.warehouses?.[index]?.uid)"
                          @keydown="e => preventKeydown(e)"
                          @focus="e => onInputFocused('instance', index, instance_index)"
                          @blur="e => onInputBlurred('instance', index, instance_index)"
                        >
                          <template #label>
                            <Tippy v-if="state.form?.warehouses?.[index]?.instances?.[instance_index]?.instance_name?.length > 25">
                              <div class="text-sm text-gray-700 font-normal">
                                {{ $filters.truncate(state.form?.warehouses?.[index]?.instances?.[instance_index]?.instance_name, 25) }}
                              </div>
                              <template #content>
                                <div class="break-all">
                                  {{ state.form?.warehouses?.[index]?.instances?.[instance_index]?.instance_name }}
                                </div>
                              </template>
                            </Tippy>
                            <div v-else class="text-sm text-gray-700 font-normal">
                              {{ state.form?.warehouses?.[index]?.instances?.[instance_index]?.instance_name }}
                            </div>
                          </template>
                        </TextElement>
                      </ObjectElement>
                    </template>
                  </ListElement>
                </ObjectElement>
              </template>
            </ListElement>
          </div>
        </HawkModalContent>
        <HawkModalFooter>
          <template #right>
            <div class="flex justify-end gap-4">
              <HawkButton type="outlined" @click="emit('close')">
                {{ $t('Close') }}
              </HawkButton>
              <ButtonElement button-class="w-full bg-blue-600" name="submit" :disabled="!auth_store.check_permission('modify_bom', route.params.asset_id)" :submits="true">
                {{ $t('Save') }}
              </ButtonElement>
            </div>
          </template>
        </HawkModalFooter>
      </div>
    </Vueform>
  </HawkModalContainer>
</template>
