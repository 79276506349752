<!-- eslint-disable no-prototype-builtins -->
<script setup>
import { onClickOutside } from '@vueuse/core';
import anyDateParser from 'any-date-parser';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Parser } from 'hot-formula-parser';
import { flatMap, isEmpty, isEqual, keyBy, omit } from 'lodash-es';
import { useModal } from 'vue-final-modal';
import HawkHandsOnTable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';
import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useMembers } from '~/common/composables/members.js';
import { all_countries } from '~/common/constants/all-countries.js';
import { currencies } from '~/common/utils/constants.ts';
import FormBuilderTableFieldPopup from '~/forms/components/form-builder/form-builder-table-field-popup.vue';
import FormBuilderTableValuesPopup from '~/forms/components/form-builder/form-builder-table-values-popup.vue';
import { useTableSummaryValues } from '~/forms/composables/form-builder-table-summary.composable.js';
import { useFormFieldConfiguration } from '~/forms/composables/form-field-schema.composable.js';
import { useFormDetailStore } from '~/forms/store/form-detail.store';
import { useFormTemplateDetailStore } from '~/forms/store/form-template-detail.store';

const props = defineProps({
  section: {
    type: Object,
    required: true,
  },
  valueMap: {
    type: Object,
  },
  disabled: {
    type: Boolean,
  },
  options: {
    type: Object,
    default: () => ({
      add_section: true,
      fields_mandatory: false,
      edit_prefilled_data: true,
      disable_summary_calculation: true,
      update_field_properties: true,
      add_rows: true,
      disabled: false,
      delete_row: true,
    }),
  },
});

const emit = defineEmits(['updateValues', 'headerClicked', 'footerNameSlug', 'reRenderTable']);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);

const {
  $services,
  $t,
  $toast,
  route,
} = useCommonImports();

const url_query_params = {};
if (route.query.form) {
  const { store_key } = JSON.parse(atob(route.query.form));
  url_query_params.store_key = store_key;
}

const form_detail_store = useFormDetailStore(url_query_params.store_key);
const form_template_detail_store = useFormTemplateDetailStore();
const row_parser = new Parser();
let current_value_index = 0;
const open_popup_field_types = ['attachment', 'signature', 'multi_text', 'members', 'checkbox'];
const row_heights = 45; // handson table row height in 'px'

const show_summary = ref(true);
const max_length = ref(1);
const hands_on_table_instance = ref(null);

const table_state = reactive({
  data: [{}],
  columns: [],
  summary_config: null,
  force_re_render: 0,
  table_height: '100px',
});

const current_section_uid = props.section.uid;
const current_section = ref((props.options.update_field_properties ? form_template_detail_store?.form_template_detail : form_detail_store?.form_template).sections?.find(section => section.uid === current_section_uid));
const section = computed(() => props.section);
const current_fields = computed(() => current_section.value?.fields?.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active') || []);
const is_form_submitted = computed(() => form_detail_store?.form_detail?.status?.name === 'Submitted');

const fields_map = computed(() => keyBy(current_fields.value, 'uid'));
const field_values = ref([]);
const selected_row = ref(null);
const selected_column = ref(null);
const insert_column_position = ref(null);
const column_previous_field = ref(undefined); // null is for adding column at start undefined for adding column at end
const section_summary = computed(() => section.value?.properties?.section_summary);
const deleting_row = ref(null);
const table_container = ref(null);

const { footerFunction, formatData, setHotParserVariables, parser: summary_parser } = useTableSummaryValues(section_summary, current_fields, props.valueMap?.summaries?.[section.value.uid], props.options);
const { getUserDetails } = useMembers();

const getCountryForField = country => country.iso2 === field?.config?.iso;
const getCountry = () => all_countries.find(getCountryForField);

const handsOnTableColumns = computed(() => current_fields.value.map((field) => {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

  const field_renderer_editor_map = {
    'short_text': {
      type: 'text',
      ...(field?.config?.prefix ? { prefix: field.config.prefix } : {}),
      ...(field?.config?.suffix ? { suffix: field.config.suffix } : {}),
      validator: 'default-validator',
      config: {
        field_type: 'text',
        required: field?.mandatory,
        min: field?.config?.min_length,
        max: field?.config?.max_length,
      },
      renderer: 'suffixPrefixRenderer',
    },
    'number': {
      type: 'numeric',
      ...(field?.config?.prefix ? { prefix: field.config.prefix } : {}),
      ...(field?.config?.units ? { suffix: field.config.units } : {}),
      validator: 'default-validator',
      config: {
        field_type: 'numeric',
        required: field?.mandatory,
        min: field?.config?.minimum,
        max: field?.config?.maximum,
      },
      renderer: 'suffixPrefixRenderer',
    },
    'money': {
      type: 'numeric',
      ...(field?.config?.code ? { prefix: currencies[field?.config?.code || 'USD']?.symbol } : {}),
      renderer: 'suffixPrefixRenderer',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'phone': {
      ...(field?.config?.code ? { prefix: `+${field?.config?.code || getCountry()?.dialCode}` } : {}),
      renderer: 'suffixPrefixRenderer',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'yes-no': {
      renderer: 'triStateRenderer',
      editor: 'triStateCheckboxEditor',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'checkbox': {
      renderer: 'multiSelectRenderer',
      editor: 'multiSelectAutocompleteEditor',
      options: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
      validator: 'default-validator',
      config: {
        field_type: 'multi-select',
        required: field?.mandatory,
        min: field?.properties?.minselect,
        max: field?.properties?.maxselect,
      },
    },
    'radio': {
      type: 'autocomplete',
      source: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
      strict: true,
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'dropdown': {
      type: 'autocomplete',
      source: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
      strict: true,
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'members': {
      renderer: 'membersRenderer',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'attachment': {
      renderer: 'fileUploadRenderer',
      editor: false,
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'signature': {
      editor: false,
      validator: 'default-validator',
      config: {
        field_type: 'signature',
        required: field?.mandatory,
      },
    },
    'multi_text': {
      editor: false,
      validator: 'default-validator',
      config: {
        field_type: 'multi_text',
        required: field?.mandatory,
      },
    },
    'formula': {
      editor: false,
      readOnly: true,
      renderer: formulaRenderer,
    },
    'email': {
      renderer: 'emailRenderer',
      validator: 'default-validator',
      config: {
        field_type: 'email',
        required: field?.mandatory,
      },
    },
    'url': {
      renderer: 'urlRenderer',
      validator: 'default-validator',
      config: {
        field_type: 'url',
        required: field?.mandatory,
      },
    },
    'datetime': {
      dateTime: true,
      renderer: 'dateTimeRenderer',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'date': {
      type: 'date',
      renderer: 'dateTimeRenderer',
      validator: 'default-validator',
      config: {
        required: field?.mandatory,
      },
    },
    'time': {
      renderer: timeRenderer,
      type: 'time',
    },
  };

  return {
    data: field.uid,
    text: field.name,
    className: 'htLeft htMiddle',
    readOnly: props.options.disabled,
    ...(field_renderer_editor_map[field_type] ? field_renderer_editor_map[field_type] : {}),
    ...(section.value.properties?.column_width_config?.[field.uid] ? { width: section.value.properties.column_width_config[field.uid].size } : {}),
  };
}));

const hands_on_table_column_options = {
  items: {
    add_col_left: {
      name: 'Insert column left',
      disabled() {
        return false;
      },
      callback() {
        const column_index_selected = hands_on_table_instance.value.getSelected()[0][1];

        if (column_index_selected === 0) {
          insert_column_position.value = 0;
          column_previous_field.value = null; // add column at start
        }
        else {
          insert_column_position.value = Math.ceil(((column_index_selected - 1) + column_index_selected) / 2);
          column_previous_field.value = current_fields.value[column_index_selected - 1]?.uid;
        }

        hands_on_table_instance.value.deselectCell();
        addNewField();
      },
    },
    add_col_right: {
      name: 'Insert column right',
      disabled() {
        return false;
      },
      callback() {
        const column_index_selected = hands_on_table_instance.value.getSelected()[0][1];

        if (column_index_selected === current_fields.value.length - 1) {
          insert_column_position.value = current_fields.value.length;
          column_previous_field.value = undefined; // add column at end
        }
        else {
          insert_column_position.value = Math.ceil(((column_index_selected + 1) + column_index_selected) / 2);
          column_previous_field.value = current_fields.value[column_index_selected]?.uid;
        }

        hands_on_table_instance.value.deselectCell();
        addNewField();
      },
    },
    edit_col: {
      name: 'Edit column',
      callback() {
        const column_index_selected = hands_on_table_instance.value.getSelected()[0][1];
        const field_uid = hands_on_table_instance.value.getColumnMeta(column_index_selected).data;

        updateColumnField(fields_map.value[field_uid]);
      },
    },
    clear_column: {
      name: 'Clear column',
    },
    delete_col: {
      name: 'Delete column',
      hidden() {
        return current_fields.value.length < 2;
      },
      callback() {
        const column_index_selected = hands_on_table_instance.value.getSelected()[0][1];
        const selected_field_uid = hands_on_table_instance.value.getColumnMeta(column_index_selected).data;

        $services.forms.delete({
          attribute: `fields/${selected_field_uid}`,
        });

        current_section.value.fields = current_section.value.fields.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active');
        const index = current_section.value.fields.findIndex(field => field.uid === selected_field_uid);
        current_section.value.fields.splice(index, 1);

        hands_on_table_instance.value.updateSettings({ columns: handsOnTableColumns.value, columnSummary: columnFooterSummary() });
      },
    },
  },
};

const hands_on_table_right_click_options = {
  row_above: {
    name: 'Insert row above',
    disabled() {
      return !props.section?.properties?.can_add_rows;
    },
  },
  row_below: {
    name: 'Insert row below',
    disabled() {
      return !props.section?.properties?.can_add_rows;
    },
  },
  remove_row: {
    name: 'Remove row',
    disabled() {
      return false;
    },
  },
  undo: {
    name: 'Undo',
    disabled() {
      return !hands_on_table_instance.value?.undoRedo?.isUndoAvailable();
    },
  },
  redo: {
    name: 'Redo',
    disabled() {
      return !hands_on_table_instance.value?.undoRedo?.isRedoAvailable();
    },
  },
  cut: {
    name: 'Cut',
  },
  copy: {
    name: 'Copy',
  },
  paste: {
    name: 'Paste',
    callback() {
      const copyPaste = hands_on_table_instance.value.getPlugin('copyPaste');
      navigator.clipboard
        .readText()
        .then(clipText => (copyPaste.paste(clipText)));
    },
  },
};

const form_field_configuration_map = useFormFieldConfiguration();
const non_deletable_rows = {};
if (props.valueMap) {
  (current_section.value?.fields?.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active') || []).forEach((field) => {
    field.properties = field.properties || {};
    field.properties._value = field?.properties?.value || [];
    if (!props.options.edit_prefilled_data) {
      field.properties._value.forEach((value, index) => {
        non_deletable_rows[index] = props.delete_row && (!!value || non_deletable_rows[index]);
      });
    }
    field.properties.value = props.valueMap[field.uid] || (field?.properties?.value ? [...field.properties.value] : []);
  });
}

field_values.value = current_fields.value.map(field => props.valueMap?.[field.uid] || field?.properties?.value || []);
max_length.value = Math.max(...field_values.value.map(values => (values || []).length));

function generate_table_data() {
  const data = [];

  // Generate row data
  generateRowsData(data);

  // Add disableColumns if necessary
  if (!props.options.update_field_properties) {
    addDisableColumnsToRows(data);
  }

  // Add empty row if necessary
  ensureEmptyRow(data);

  // Add summary row(s) if needed
  addSummaryRows(data);

  return data;
}

function generateRowsData(data) {
  for (let row_index = 0; row_index < max_length.value; row_index++) {
    data[row_index] = {};

    current_fields.value.forEach((field, column_index) => {
      const configuration_map = form_field_configuration_map[field?.properties?.type || field.type];
      const config = configuration_map?.(field);
      const value = props.options.update_field_properties ? field?.properties?.value?.[row_index] || '' : field_values.value?.[column_index]?.[row_index];
      current_value_index = row_index;
      preProcessTableData(field, config, value, data, row_index);
    });
  }
}

function addDisableColumnsToRows(data) {
  data.forEach((row, row_index) => {
    const disableColumns = [];
    for (const [key, value] of Object.entries(row)) {
      const has_value = typeof value === 'object' ? !isEmpty(value) : !!value;
      const disable_field = !props.options.edit_prefilled_data && has_value;
      disable_field && disableColumns.push(key);
    }
    data[row_index] = {
      ...data[row_index],
      disableColumns,
    };
  });
}

function ensureEmptyRow(data) {
  if (!data.length) {
    data.push({});
  }
}

function formulaRenderer(instance, td, _row, _col, prop, cell_value, cellProperties) {
  const { visualRow } = cellProperties;
  const formula_field = fields_map.value[prop];
  const field_result = row_parser.parse(formula_field?.config?.formula).result;

  if (current_value_index === visualRow) {
    td.innerHTML = (field_result && formula_field?.config?.prefix) ? `${formula_field?.config?.prefix || ''} ${field_result}` : field_result;
    table_state.data[visualRow][formula_field.uid] = field_result;
  }
  else {
    td.innerHTML = cell_value;
  }

  td.className = 'htMiddle';
}

function timeRenderer(instance, td, _row, _col, prop, cell_value, cellProperties) {
  if (cellProperties.type === 'time') {
    const time_with_date = `${dayjs().format('DD/MM/YYYY')} , ${cell_value}`;
    const date = anyDateParser.fromAny(time_with_date);
    const parsedDate = dayjs(date);

    if (!parsedDate.isValid()) {
      return;
    }

    td.innerHTML = cell_value ? parsedDate.format('h:mm a') : '';
    td.className = 'htMiddle';
  }
}

function addSummaryRows(data) {
  if (section_summary.value?.summary_type && show_summary && data.length) {
    if (section_summary.value?.summary_type === 'column_summary') {
      data.push({
        disableEdit: true,
        isSummaryRow: true,
      });
    }
    if (section_summary.value?.summary_type === 'detailed_summary') {
      const summary_entries = Object.entries(footerFunction());
      summary_entries.forEach(() => {
        data.push({
          disableEdit: true,
          isSummaryRow: true,
        });
      });
    }
  }
}

onClickOutside(table_container, () => {
  selected_row.value = null;
  selected_column.value = null;
});

const { open: openValuesModal, close: closeValuesModal, patchOptions: patchValuesModal } = useModal({
  component: FormBuilderTableValuesPopup,
  attrs: {
    section: current_section.value,
    fields: current_fields.value,
    form_data: form_template_detail_store?.form_template_detail,
    mandatory: props.options.fields_mandatory,
    is_data_editable: props.options.edit_prefilled_data,
    update_field_properties: props.options.update_field_properties,
    row: selected_row,
    field_values,
    is_disabled: props.options.disabled,
    visibility: props.options.visibility,
    onClose() {
      closeValuesModal();
    },
    onRemove(row_index) {
      deleteRow(row_index, 1);
      hands_on_table_instance.value.updateData(generate_table_data());
    },
    async submit(form, row) {
      const index = row.index;
      if (props.options.update_field_properties) {
        const payload = current_section.value.fields.filter(field => field.status === 'active').map((field, field_index) => {
          field.properties.value = field?.properties?.value || [];
          field.properties.value[index] = form.requestData[current_fields.value[field_index]?.uid];
          return {
            uid: field.uid,
            properties: {
              ...field.properties,
            },
          };
        });

        await $services.forms.patch({
          attribute: 'fields',
          body: { form_uid: form_template_detail_store?.form_template_detail.uid, update_fields: payload },
        });
      }
      if (props.valueMap) {
        field_values.value.forEach((values, field_index) => values[index] = form?.requestData?.hasOwnProperty(current_fields.value[field_index]?.uid) ? form.requestData[current_fields.value[field_index]?.uid] : values[index]);
      }
      hands_on_table_instance.value.loadData(generate_table_data());
      emit('updateValues', field_values);
      closeValuesModal();
    },
  },
});

async function deleteRow(index, amount) {
  try {
    deleting_row.value = index;
    max_length.value = max_length.value - amount;

    field_values.value.forEach(values => values?.splice(index, amount));
    const payload = current_fields.value.map((field, field_index) => {
      field.properties.value = field_values.value[field_index];
      return {
        uid: field.uid,
        properties: {
          ...field.properties,
        },
      };
    });
    if (props.options.update_field_properties) {
      await $services.forms.patch({
        attribute: 'fields',
        body: { form_uid: form_template_detail_store?.form_template_detail?.uid, update_fields: payload },
      });
    }
    selected_row.value = null;
    emit('updateValues', field_values);
    closeValuesModal();
  }
  catch (e) {
    logger.log(e);
  }
  deleting_row.value = null;
}

const { open: openFieldsModal, close: closeFieldsModal, patchOptions: patchFieldsModal } = useModal({
  component: FormBuilderTableFieldPopup,
  attrs: {
    fields: current_fields.value,
    section: current_section.value,
    selected_column,
    onClose() {
      closeFieldsModal();
    },
    onDelete(selected_field) {
      current_section.value.fields = current_section.value.fields.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active');
      const index = current_section.value.fields.findIndex(field => field.uid === selected_field.uid);
      current_section.value.fields.splice(index, 1);

      hands_on_table_instance.value.updateSettings({ columns: handsOnTableColumns.value, columnSummary: columnFooterSummary() });
      closeFieldsModal();
    },
    submit(response_field) {
      current_section.value.fields = current_section.value.fields.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active');
      const index = current_section.value.fields.findIndex(field => field.uid === response_field.uid);
      if (index >= 0) {
        // For updating the existing column
        current_section.value.fields[index] = response_field;
        hands_on_table_instance.value.updateData(generate_table_data()); // To update the renamed options(checkbox, radio, dropdown)
      }
      else {
        // Insert new column to left or right of selected one
        current_section.value.fields.splice(insert_column_position.value, 0, response_field);
        insert_column_position.value = null;
      }

      hands_on_table_instance.value.updateSettings({ columns: handsOnTableColumns.value, columnSummary: columnFooterSummary() });
      closeFieldsModal();
    },
  },
});

function onRowExpand(row_index) {
  selected_row.value = { index: row_index };
  patchValuesModal({
    attrs: {
      section: current_section.value,
      fields: current_fields.value,
    },
  });
  openValuesModal();
}

function addNewField() {
  patchFieldsModal({
    attrs: {
      previousField: column_previous_field.value,
    },
  });
  openFieldsModal();
}

function updateColumnField(field) {
  patchFieldsModal({
    attrs: {
      fields: current_fields.value,
    },
  });
  selected_column.value = field;
  openFieldsModal();
}

function addNewValue() {
  max_length.value++;
  setTimeout(() => {
    const rows = hands_on_table_instance.value.countRows();
    selected_row.value = { index: rows - 1 };
    openValuesModal();
  }, 100);
}

function loadRowParserEvents(parser) {
  parser.on('callVariable', (name, done) => {
    const field_uid = parser.getVariable(name);
    const field = fields_map.value[field_uid];
    const result = table_state.data.reduce((acc, obj) => {
      Object.keys(obj).forEach((key) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj[key]);
      });
      return acc;
    }, {});

    if (!isEmpty(table_state.data[0]))
      field.properties.value = result[field_uid];

    const values = field.properties?.value?.map(val => formatData(field, val));
    const final_value = values[current_value_index];

    done(Number(final_value) ? Number(final_value).toFixed(2) : final_value);
  });
}

async function columnResized(current_header_data, col_header_width_map) {
  if (props.options.update_field_properties) {
    const { data } = await $services.forms.patch({
      attribute: `sections/${section.value.uid}`,
      body: {
        section: {
          properties: { ...current_section.value.properties, column_width_config: col_header_width_map },
        },
      },
    });

    form_template_detail_store.form_template_detail.sections.forEach((template_section) => {
      if (template_section.uid === section.value.uid)
        template_section.properties = data.section.properties;
    });
    current_section.value.properties = data.section.properties;
  }
}

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: row_heights,
    viewPortRowRenderingOffset: 10,
    ...(props.options.update_field_properties ? {} : { dropdownMenu: false }),
    manualColumnResize: true,
    afterColumnResize: () => {
      const columnWidths = [];

      // Get the current width of each column
      for (let i = 0; i < hands_on_table_instance.value.countCols(); i++) {
        columnWidths.push({
          size: hands_on_table_instance.value.getColWidth(i),
          id: hands_on_table_instance.value.getColumnMeta(i).data,
        });
      }
      columnResized(null, keyBy(columnWidths, 'id'));
    },
    afterColumnMove: async (movedColumns, finalIndex, dropIndex, movePossible, orderChanged) => {
      if (!orderChanged)
        return;

      const field_name_map = keyBy(current_fields.value, 'name');
      const arranged_fields = hands_on_table_instance.value.getColHeader().map(header => field_name_map[header]);

      try {
        const { data } = await $services.forms.patch({
          attribute: 'fields/reorder',
          body: { fields: arranged_fields.map(field => field.uid) },
          toast: false,
        });

        form_template_detail_store.form_template_detail.sections.forEach((template_section) => {
          if (template_section.uid === section.value.uid)
            template_section.fields = data.fields;
        });
        hands_on_table_instance.value.updateSettings({ columns: handsOnTableColumns.value, columnSummary: columnFooterSummary() });
      }
      catch (error) {
        console.error(error);
        logger.error(error);
        $toast({ text: $t('Failed to configure columns'), type: 'error' });
      }
    },
    afterRemoveRow: async (index, amount) => {
      await deleteRow(index, amount);
    },
  };
}

function removeHtmlContent(htmlString) {
  const hasHtmlTags = /<[a-z][\s\S]*>/i.test(htmlString);

  if (hasHtmlTags) {
    const regex = /<[^>]*>/g;
    return htmlString.replace(regex, '');
  }
  else {
    return htmlString;
  }
}

const enrichment_function_map = {
  short_text: (field, value) => value || '',
  number: (field, value) => value || '',
  long_text: (field, values) => removeHtmlContent(values),
  money: (field, value) => value,
  checkbox: (field, values) => values
    ? values
      .map(
        value =>
          field?.config.find(conf => conf.uid === value).name,
      )
    : '',
  phone: (field, value) => value,
  members: (field, value) => value,
  attachment: (field, values) => (values || [])?.map(value => value.thumbnails?.small),
  signature: (field, value) => {
    const signature_data = Array.isArray(value) ? value[0] : value;

    if (!signature_data?.meta)
      return '';

    const { members_details } = getUserDetails(signature_data.meta.owner?.uid || signature_data.meta.owner);

    return `${members_details[0].name || email} [${dayjs(signature_data.meta.created_at).format('D MMMM YYYY, hh:mm a')}]`;
  },
  formula: (field) => {
    const field_result = row_parser.parse(field?.config?.formula).result;
    return (field_result && field?.config?.prefix) ? `${field?.config?.prefix || ''} ${field_result}` : field_result;
  },
  multi_text: (field, values) => {
    return values;
  },
  date: (field, value) => {
    return value ? dayjs(value).format('D MMMM YYYY') : '';
  },
  time: (field, value) => {
    return value ? dayjs(value).format('h:mm a') : '';
  },
  datetime: (field, value) => {
    return value ? dayjs(value).format('D MMMM YYYY hh:mm A') : '';
  },
};

const value_transform_function_map = {
  'checkbox': (field, values) => {
    const config_name_map = keyBy(field.config, 'name');
    return (values || [])?.map(value => config_name_map[value].uid);
  },
  'radio': (field, value) => {
    const config_name_map = keyBy(field.config, 'name');
    return value && config_name_map[value]?.uid;
  },
  'dropdown': (field, value) => {
    const config_name_map = keyBy(field.config, 'name');
    return value && config_name_map[value]?.uid;
  },
  'yes-no': (field, value) => {
    const config_name_map = keyBy(field.config, 'name');
    return value && config_name_map[value]?.uid;
  },
  'formula': (field) => {
    const field_result = row_parser.parse(field?.config?.formula).result;

    return (field_result && field?.config?.prefix) ? `${field?.config?.prefix || ''} ${field_result}` : field_result;
  },
  'multi_text': (field, values) => {
    return values;
  },
  'date': (field, value) => {
    const date = anyDateParser.fromAny(value);
    const parsedDate = dayjs(date);

    return value ? parsedDate.toISOString() : null;
  },
  'time': (field, value) => {
    const time_with_date = `${dayjs().format('DD MMM YYYY')} , ${value}`;
    const date = anyDateParser.fromAny(time_with_date);
    const parsedDate = dayjs(date);

    return value ? parsedDate.toISOString() : null;
  },
  'datetime': (field, value) => {
    const date = anyDateParser.fromAny(value);
    const parsedDate = dayjs(date);

    return value ? parsedDate.toISOString() : null;
  },
};

function preProcessTableData(field, config, value, processed_table_data, index) {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

  const default_value_fn = (field, value) => {
    const final_value = config?.get_submitted_value(value);

    return final_value === '-' ? '' : final_value;
  };

  const pre_process_function = enrichment_function_map[field_type] || default_value_fn;
  processed_table_data[index][field.uid] = pre_process_function(field, value);
}

// postProcessTableData
function transformFiedlValueData(table_field_data) {
  return table_field_data.reduce((acc, item) => {
    for (const key in item) {
      if (key === 'disableColumns' && !fields_map.value[key])
        continue;

      if (!acc[key]) {
        acc[key] = []; // Initialize the array if it doesn't exist
      }

      const field = fields_map.value[key];
      const final_default_value = item[key] === '' ? null : item[key];
      const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

      const transformed_value = value_transform_function_map[field_type]?.(field, item[key]) || final_default_value;
      acc[key].push(transformed_value); // Push the value into the respective array
    }

    return acc;
  }, {});
}

async function afterChange(table_data) {
  if (!table_data)
    return;
  const filtered_table_data = table_data.filter(data => !data.isSummaryRow);
  const processed_table_data = transformFiedlValueData(filtered_table_data);
  const payload = [];

  max_length.value = filtered_table_data.length;

  for (const [key] of Object.entries(processed_table_data)) {
    payload.push({
      uid: key,
      properties: {
        ...(omit(fields_map.value[key]?.properties || {}, '_value')),
        value: processed_table_data[key],
      },
    });
  }

  if (props.options.update_field_properties) {
    await $services.forms.patch({
      attribute: 'fields',
      body: { form_uid: form_template_detail_store?.form_template_detail.uid || route.params.template_uid, update_fields: payload },
    });

    form_template_detail_store.form_template_detail.sections.forEach((template_section) => {
      if (template_section.uid === section.value.uid) {
        template_section.fields.filter(field => field.status === 'active').forEach((field, column_index) => {
          field_values.value[column_index] = processed_table_data[field.uid];
          field.properties = {
            ...field.properties,
            ...fields_map.value[field.uid].properties,
            value: processed_table_data[field.uid],
          };
        });
      }
    });
  }
  if (props.valueMap) {
    (current_section.value?.fields?.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active') || []).forEach((field, column_index) => {
      field_values.value[column_index] = processed_table_data[field.uid];
      field.properties = {
        ...field.properties,
        ...fields_map.value[field.uid].properties,
        value: processed_table_data[field.uid],
      };
    });
    emit('updateValues', field_values);
  }
}

function calculateColumnSummary(field) {
  const updatedData = (hands_on_table_instance.value?.getSourceData() || table_state.data).filter(row_data => !row_data.isSummaryRow);

  if (props.options.disable_summary_calculation && props.valueMap?.summaries?.[section.value.uid]?.values[field.uid])
    return props.valueMap.summaries[section.value.uid].values[field.uid];
  const summary_data = section_summary.value?.data[field.uid];

  if (summary_data?.footer) {
    const values = updatedData.map(row_data => row_data[field.uid]).map(val => formatData(field, val)) || [];
    const _values = field.properties?._value?.map(val => formatData(field, val)) || [];
    const final_values = values.length === 0 ? _values : values;
    if (summary_data?.footer === 'Count')
      return final_values?.flat()?.filter(value => !!value)?.length;
    const formula = summary_parser.parse(`${summary_data.footer}(${final_values.toString()})`);

    return Number(formula.result) ? Number(formula.result).toFixed(2) : formula.result;
  }
}

function calculateDetailedSummary() {
  if (props.options.disable_summary_calculation && props.valueMap?.summaries?.[section.value.uid]?.values)
    return props.valueMap.summaries[section.value.uid].values;
  const summary_data = section_summary.value.data;
  const result = {};

  summary_data.forEach((data) => {
    const formula = summary_parser.parse(data.formula);
    result[data.name] = Number(formula.result) ? Number(formula.result).toFixed(2) : 0;
  });

  return result;
}

function columnFooterSummary() {
  if (section_summary.value?.summary_type === 'column_summary') {
    return current_fields.value.map((field, index) => ({
      type: 'custom',
      destinationRow: 0,
      destinationColumn: index,
      reversedRowCoords: true,
      forceNumeric: true,
      customFunction: () => {
        return calculateColumnSummary(field);
      },
    }));
  }
  else if (section_summary.value?.summary_type === 'detailed_summary') {
    const summary_entries = Object.entries(calculateDetailedSummary());

    return flatMap(summary_entries, ([key, value], index) => [
      {
        type: 'custom',
        reversedRowCoords: true,
        destinationRow: index,
        destinationColumn: current_fields.value.length - 1,
        customFunction() {
          return `${key}: ${value}`;
        },
      },
    ]);
  }
}
function columnSummaryFooterConfig() {
  return {
    show: !!section_summary.value?.summary_type && show_summary && !!table_state.data.filter(data => !data.isSummaryRow).length,
    type: section_summary.value?.summary_type === 'detailed_summary' ? 'detailed' : 'column',
    summaryFunction: columnFooterSummary,
    ...(section_summary.value?.summary_type === 'detailed_summary' && { summaryRowsLength: section_summary.value?.data?.length || 0 }),
  };
}

function onCellSelect({ visualRow, prop: fieldUID }) {
  current_value_index = visualRow;
  if (!open_popup_field_types.includes(fields_map.value[fieldUID].properties?.type))
    return;
  selected_row.value = { index: visualRow };
  patchValuesModal({
    attrs: {
      focusFieldUID: fieldUID,
      section: current_section.value,
      fields: current_fields.value,
    },
  });
  openValuesModal();
}

async function afterRowMove(table_data) {
  if (!table_data)
    return;
  const filtered_table_data = table_data.filter(data => !data.isSummaryRow);
  const processed_table_data = transformFiedlValueData(filtered_table_data);
  const payload = [];

  for (const [key] of Object.entries(processed_table_data)) {
    payload.push({
      uid: key,
      properties: {
        ...(omit(fields_map.value[key]?.properties || {}, '_value')),
        value: processed_table_data[key],
      },
    });
  }

  if (props.options.update_field_properties) {
    await $services.forms.patch({
      attribute: 'fields',
      body: { form_uid: form_template_detail_store?.form_template_detail.uid || route.params.template_uid, update_fields: payload },
    });

    form_template_detail_store.form_template_detail.sections.forEach((template_section) => {
      if (template_section.uid === section.value.uid) {
        template_section.fields.filter(field => field.status === 'active').forEach((field, column_index) => {
          field_values.value[column_index] = processed_table_data[field.uid];
          field.properties = {
            ...field.properties,
            ...fields_map.value[field.uid].properties,
            value: processed_table_data[field.uid],
          };
        });
      }
    });
  }
  if (props.valueMap) {
    (current_section.value?.fields?.filter(field => props.options.visibility ? props.options.visibility(current_section.value, field) !== 'hidden' : field.status === 'active') || []).forEach((field, column_index) => {
      field_values.value[column_index] = processed_table_data[field.uid];
      field.properties = {
        ...field.properties,
        ...fields_map.value[field.uid].properties,
        value: processed_table_data[field.uid],
      };
    });
    emit('updateValues', field_values);
  }
}

function getTableHeight() {
  if (hands_on_table_instance.value) {
    const rect = hands_on_table_instance.value?.rootElement?.querySelector('div.ht_master.handsontable > div > div')?.getBoundingClientRect();

    if (rect?.height === table_state.table_height || (table_state.table_height === 720 && rect?.height >= 720))
      return;

    table_state.table_height = rect?.height > 720 ? 720 : rect?.height;

    setTimeout(() => {
      hands_on_table_instance.value.render();
    }, 0);
  }
}

watch(
  section_summary,
  (new_section_data, old_section_data) => {
    if (!new_section_data || !old_section_data)
      return;
    if (!isEqual(new_section_data.footer, old_section_data.footer) || new_section_data.summary_type !== old_section_data.summary_type) {
      emit('reRenderTable');
    }
  },
);

watch(
  () => form_detail_store?.is_slug_mode,
  () => {
    table_state.force_re_render++;
  },
);

watch(max_length, (new_len, old_len) => {
  if (new_len !== old_len) {
    getTableHeight();
  }
});

onMounted(() => {
  setHotParserVariables(row_parser);
  loadRowParserEvents(row_parser);

  table_state.data = generate_table_data();
  table_state.columns = handsOnTableColumns.value;
  table_state.summary_config = columnSummaryFooterConfig();
});
</script>

<template>
  <div>
    <HawkLoader v-if="!hands_on_table_instance" />
    <div v-show="hands_on_table_instance" ref="table_container">
      <div :style="{ 'height': `${table_state.table_height}px`, 'min-height': '100px' }" class="overflow-scroll scrollbar pointer-events-auto" :class="{ '!pointer-events-none': props.disabled }">
        <HawkHandsOnTable
          :key="table_state.force_re_render"
          :data="table_state.data"
          :columns="table_state.columns"
          :hot-settings="hotSettings()"
          :manual-column-move="props.options.update_field_properties"
          :manual-row-move="!props.options.disabled"
          :col-headers="index => handsOnTableColumns[index]?.text"
          :read-only="!props.options.update_field_properties && is_form_submitted"
          :hot-table-id="`form-${props.options.update_field_properties ? 'builder' : ''}-table-section-${section.uid}`"
          :auto-wrap-col="true"
          :auto-wrap-row="true"
          :display-expand-icon="false"
          :row-header-icon="true"
          :disable-required-validation="props.options.update_field_properties && true"
          :row-sorting="true"
          :adding-rows="true"
          :add-new-row-on-enter="props.section?.properties?.can_add_rows"
          :enable-slugs="form_detail_store?.is_slug_mode"
          :columns-menu="props.options.update_field_properties ? hands_on_table_column_options : { items: [] }"
          :right-click-menu="hands_on_table_right_click_options"
          :column-summary-config="table_state.summary_config"
          height="100%"
          @after-change="afterChange"
          @add-row-on-enter="max_length++"
          @after-rows-dragged="afterRowMove"
          @cell-dbl-click="onCellSelect"
          @slug-clicked="(col_index) => { emit('headerClicked', current_fields[col_index]); $toast({ text: 'Copied!', type: 'success', timeout: 1000 }) }"
          @click.stop
          @row-header-icon-click="onRowExpand"
          @ready="hands_on_table_instance = $event"
          @after-view-render="getTableHeight()"
        />
      </div>
      <div v-if="false" class="flex justify-between mt-4">
        <hawk-button v-if="props.options.add_rows" type="text" @click.stop="addNewValue">
          <icon-hawk-plus />
          <span>
            {{ $t('Add row') }}
          </span>
        </hawk-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  #hawk_table {
    :deep(.hawk_table_loader_no_data_container) {
      @apply h-14
    }

    :deep(.hawk_table_no_data) {
      @apply h-14
    }
  }
</style>
