<!-- eslint-disable vue/prop-name-casing -->
<script setup>
import { useModal } from 'vue-final-modal';
import TransmittalWorkflowBlockEditPopup from '~/dms/components/transmittals/workflow/graph/transmittal-workflow-block-edit-popup.vue';

const props = defineProps({
  published: {
    type: Boolean,
  },
  is_final: {
    type: Boolean,
  },
  sub_step: {
    type: Object,
    default: () => ({ members: [] }),
  },
  disabled: {
    type: Boolean,
  },
  visible_options: {
    type: Object,
    default: () => {
      return {
        edit: true,
        delete: true,
        skip_step: false,
      };
    },
  },
});

const emit = defineEmits(['delete']);

const sub_step = computed(() => props.sub_step);
const existing_members_in_workflow = inject('existing_members_in_workflow');

const { open: openEditModal, close: closeEditModal } = useModal({
  component: TransmittalWorkflowBlockEditPopup,
  attrs: {
    sub_step,
    published: props.published,
    disabled: props.disabled,
    existing_members: existing_members_in_workflow,
    onClose() {
      closeEditModal();
    },
    async submit(data, form, state) {
      sub_step.value.name = form.data.name;
      sub_step.value.members = form.data.members.map(user => user.uid || user);
      sub_step.value.condition = form.data.condition;
      sub_step.value.min_approvals = form.data.min_approvals;
      sub_step.value.reminders = form.data?.reminders?.is_enabled ? form.data.reminders : undefined;
      sub_step.value.escalations = form.data?.escalations?.is_enabled ? form.requestData.escalations : undefined;
      if (sub_step.value.reminders)
        sub_step.value.reminders.custom_email = state.reminders_email;
      if (sub_step.value.escalations)
        sub_step.value.escalations.custom_email = state.escalation_email;
      closeEditModal();
    },
  },
});

const $t = inject('$t');

const action_items = computed(() => {
  const items = [
    {
      uid: 'skip_step',
      label: sub_step.value.skip ? $t('Enable step') : $t('Skip step'),
      disabled: props.disabled,
      on_click: () => {
        sub_step.value.skip = !sub_step.value.skip;
      },
    },
    {
      uid: 'edit',
      label: $t('Edit'),
      on_click: () => {
        openEditModal();
      },
    },
    {
      uid: 'delete',
      label: $t('Delete'),
      on_click: () => {
        emit('delete');
      },
    },
  ];
  return items.filter(item => props.visible_options[item.uid]);
});
</script>

<template>
  <div class="border border-gray-200 mt-1 p-6 rounded-lg group/block" :class="{ 'bg-gray-100': sub_step.skip }">
    <div class="flex justify-between items-end">
      <div class="flex">
        <div v-if="is_final" class="border border-success-600 bg-success-600 rounded-lg w-8 h-8 flex justify-center items-center text-white mr-4">
          <IconHawkCheckVerifiedOne class="w-3.5 h-3.5" />
        </div>
        <div v-else class="border border-primary-600 rounded-lg w-8 h-8 flex justify-center items-center text-primary-600 mr-4">
          <IconHawkCheckVerifiedOne class="w-3.5 h-3.5" />
        </div>
        <div>
          <div class="text-sm font-semibold">
            {{ sub_step.name }}
          </div>
          <div>
            <HawkMembers :members="sub_step.members.map(user => user.uid || user)" type="group" :max_badges_to_display="6" size="sm" has_border />
          </div>
        </div>
      </div>
      <div class="relative -bottom-4 -right-4 invisible group-hover/block:visible">
        <hawk-menu
          additional_trigger_classes="!ring-0 hover:bg-gray-50"
          :items="action_items"
          @click.stop=""
        />
      </div>
    </div>
  </div>
</template>
