<script setup>
// --------------------------------- Imports -------------------------------- //
import dayjs from 'dayjs';
import { flatMap, omit } from 'lodash-es';

import HawkHandsOnTable from '~/common/components/organisms/hawk-handsontable/hawk-handsontable.vue';

import { useCommonImports } from '~/common/composables/common-imports.composable.js';
import { useMembers } from '~/common/composables/members.js';
import { useFormFieldConfiguration } from '~/forms/composables/form-field-schema.composable.js';

// ---------------------------------- Props --------------------------------- //
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: true,
  },
  sectionUid: {
    type: String,
    required: true,
  },
  formUid: {
    type: String,
    required: true,
  },
});

// ---------------------------------- Emits --------------------------------- //

// ---------------------------- Injects/Provides ---------------------------- //

// ----------------------- Variables - Pinia - consts ----------------------- //
const {
  $services,
} = useCommonImports();
const form_field_configuration_map = useFormFieldConfiguration();
const { getUserDetails } = useMembers();

// --------------------- Variables - Pinia - storeToRefs -------------------- //

// ------------------- Variables - Local - consts and lets ------------------ //
const row_heights = 36; // handson table row height in 'px'

// ------------------------ Variables - Local - refs ------------------------ //
const hands_on_table_instance = ref(null);
const form_data = ref(null);

// ---------------------- Variables - Local - reactives --------------------- //
const table_state = reactive({
  data: [],
  columns: [],
  summary_config: null,
  table_height: '100px',
});

// --------------------------- Computed properties -------------------------- //
const section_data = computed(() => form_data.value.template.sections[0]);
const active_fields = computed(() => section_data.value.fields.filter(field => field.status === 'active'));
const has_summary = computed(() => form_data.value.field_values.summaries);
const summary_data = computed(() => form_data.value.field_values.summaries[props.sectionUid]);

const handsOnTableColumns = computed(() => active_fields.value.map((field) => {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

  const field_renderer_editor_map = {
    'short_text': {
      type: 'text',
      ...(field?.config?.prefix ? { prefix: field.config.prefix } : {}),
      ...(field?.config?.suffix ? { suffix: field.config.suffix } : {}),
      renderer: 'suffixPrefixRenderer',
    },
    'number': {
      type: 'numeric',
      ...(field?.config?.prefix ? { prefix: field.config.prefix } : {}),
      ...(field?.config?.units ? { suffix: field.config.units } : {}),
      renderer: 'suffixPrefixRenderer',
    },
    'money': {
      type: 'numeric',
      ...(field?.config?.code ? { prefix: currencies[field?.config?.code || 'USD']?.symbol } : {}),
      renderer: 'suffixPrefixRenderer',
    },
    'phone': {
      ...(field?.config?.code ? { prefix: `+${field?.config?.code || getCountry()?.dialCode}` } : {}),
      renderer: 'suffixPrefixRenderer',
    },
    'yes-no': {
      renderer: 'triStateRenderer',
      editor: 'triStateCheckboxEditor',
    },
    'checkbox': {
      renderer: 'multiSelectRenderer',
      editor: 'multiSelectAutocompleteEditor',
      options: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
    },
    'radio': {
      type: 'autocomplete',
      source: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
      strict: true,
    },
    'dropdown': {
      type: 'autocomplete',
      source: Array.isArray(field.config) && field.config.filter(config => !config?.deleted).map(option => option.name),
      strict: true,
    },
    'members': {
      renderer: 'membersRenderer',
    },
    'attachment': {
      renderer: 'fileUploadRenderer',
      editor: false,
    },
    'email': {
      renderer: 'emailRenderer',
    },
    'url': {
      renderer: 'urlRenderer',
    },
  };

  return {
    data: field.uid,
    text: field.name,
    className: 'htLeft htMiddle',
    readOnly: true,
    ...(field_renderer_editor_map[field_type] ? field_renderer_editor_map[field_type] : {}),
    ...(section_data.value.properties?.column_width_config?.[field.uid] ? { width: section_data.value.properties.column_width_config[field.uid].size } : {}),
  };
}));
// -------------------------------- Functions ------------------------------- //
function generate_table_data() {
  const data = [];

  // Generate row data
  generateRowsData(data);

  // Add summary row(s) if needed
  if (has_summary.value)
    addSummaryRows(data);

  return data;
}

function generateRowsData(data) {
  const filtered_field_values = omit(form_data.value.field_values, 'summaries');
  const max_length = Math.max(...Object.values(filtered_field_values).map(values => (values || [])).map(arr => arr.length));

  for (let row_index = 0; row_index < max_length; row_index++) {
    data[row_index] = {};

    active_fields.value.forEach((field) => {
      const configuration_map = form_field_configuration_map[field?.properties?.type || field.type];
      const config = configuration_map?.(field);
      const value = form_data.value.field_values[field.uid][row_index];

      preProcessTableData(field, config, value, data, row_index);
    });
  }
}

function addSummaryRows(data) {
  if (summary_data.value.type === 'column_summary') {
    data.push({
      disableEdit: true,
      isSummaryRow: true,
    });
  }
  if (summary_data.value.type === 'detailed_summary') {
    const summary_entries = Object.entries(summary_data.value.values);
    summary_entries.forEach(() => {
      data.push({
        disableEdit: true,
        isSummaryRow: true,
      });
    });
  }
}

function columnFooterSummary() {
  if (summary_data.value.type === 'column_summary') {
    return active_fields.value.map((field, index) => ({
      type: 'custom',
      destinationRow: 0,
      destinationColumn: index,
      reversedRowCoords: true,
      forceNumeric: true,
      customFunction() {
        return summary_data.value.values[field.uid];
      },
    }));
  }
  else if (summary_data.value.type === 'detailed_summary') {
    const summary_entries = Object.entries(summary_data.value.values);

    return flatMap(summary_entries, ([key, value], index) => [
      {
        type: 'custom',
        reversedRowCoords: true,
        destinationRow: index,
        destinationColumn: active_fields.value.length - 1,
        customFunction() {
          return `${key}: ${value}`;
        },
      },
    ]);
  }
}

function columnSummaryFooterConfig() {
  return {
    show: !!table_state.data.filter(data => !data.isSummaryRow).length,
    type: summary_data.value.type === 'detailed_summary' ? 'detailed' : 'column',
    summaryFunction: columnFooterSummary,
    ...(summary_data.value.type === 'detailed_summary' && { summaryRowsLength: Object.keys(summary_data.value.values)?.length || 0 }),
  };
}

function removeHtmlContent(htmlString) {
  const hasHtmlTags = /<[a-z][\s\S]*>/i.test(htmlString);

  if (hasHtmlTags) {
    const regex = /<[^>]*>/g;
    return htmlString.replace(regex, '');
  }
  else {
    return htmlString;
  }
}

const enrichment_function_map = {
  short_text: (field, value) => value || '',
  number: (field, value) => value || '',
  long_text: (field, values) => removeHtmlContent(values),
  money: (field, value) => value,
  checkbox: (field, values) => values
    ? values
      .map(
        value =>
          field?.config.find(conf => conf.uid === value).name,
      )
    : '',
  phone: (field, value) => value,
  members: (field, value) => value,
  attachment: (field, values) => (values || [])?.map(value => value.thumbnails?.small),
  signature: (field, value) => {
    const signature_data = Array.isArray(value) ? value[0] : value;

    if (!signature_data?.meta)
      return '';

    const { members_details } = getUserDetails(signature_data.meta.owner?.uid || signature_data.meta.owner);

    return `${members_details[0].name || email} [${dayjs(signature_data.meta.created_at).format('D MMMM YYYY, hh:mm a')}]`;
  },
  formula: (field, value) => {
    return (value && field?.config?.prefix) ? `${field?.config?.prefix || ''} ${value}` : value;
  },
  multi_text: (field, values) => {
    return values;
  },
};

function preProcessTableData(field, config, value, processed_table_data, index) {
  const field_type = (typeof field.config.type === 'string' ? field.config.type : null) || field.properties.type || field.type;

  const default_value_fn = (field, value) => {
    const final_value = config?.get_submitted_value(value);

    return final_value === '-' ? '' : final_value;
  };

  const pre_process_function = enrichment_function_map[field_type] || default_value_fn;
  processed_table_data[index][field.uid] = pre_process_function(field, value);
}

function hotSettings() {
  return {
    rowHeaders: true,
    rowHeights: row_heights,
    dropdownMenu: false,
    viewPortRowRenderingOffset: 10,
    manualColumnResize: false,
  };
}

function getTableHeight() {
  if (hands_on_table_instance.value) {
    const rect = hands_on_table_instance.value.rootElement.querySelector('div.ht_master.handsontable > div > div').getBoundingClientRect();

    if (rect.height === table_state.table_height || (table_state.table_height === 720 && rect.height >= 720))
      return;

    table_state.table_height = rect.height > 720 ? 720 : rect.height;

    setTimeout(() => {
      hands_on_table_instance.value.render();
    }, 0);
  }
}

// -------------------------------- Watchers -------------------------------- //

// ----------------------------- Lifecycle Hooks ---------------------------- //
onMounted(async () => {
  const { data } = await $services.forms.get({
    id: props.formUid,
    query: {
      archived: false,
      include_sections: props.sectionUid,
    },
  });

  form_data.value = data.form;

  table_state.data = generate_table_data();
  table_state.columns = handsOnTableColumns.value;
  table_state.summary_config = has_summary.value ? columnSummaryFooterConfig() : null;
});
</script>

<template>
  <HawkModalContainer content_class="rounded-lg">
    <HawkModalHeader @close="$emit('close')">
      <template #title>
        {{ props.title }}
      </template>
      <template #subtitle>
        {{ props.subtitle }}
      </template>
    </HawkModalHeader>
    <HawkModalContent class="bg-white">
      <HawkLoader v-if="!form_data && !hands_on_table_instance" />
      <div :style="{ 'height': `${table_state.table_height}px`, 'min-height': '100px' }">
        <HawkHandsOnTable
          :data="table_state.data"
          :columns="table_state.columns"
          :hot-settings="hotSettings()"
          :manual-column-move="false"
          :manual-row-move="false"
          :col-headers="index => handsOnTableColumns[index]?.text"
          :hot-table-id="`form-detail-table-section-${sectionUid}`"
          :auto-wrap-col="true"
          :auto-wrap-row="true"
          :display-expand-icon="false"
          :row-header-icon="false"
          :row-sorting="true"
          :add-new-row-on-enter="false"
          :column-summary-config="table_state.summary_config"
          height="100%"
          :read-only="true"
          @ready="hands_on_table_instance = $event"
          @after-view-render="getTableHeight()"
        />
      </div>
    </HawkModalContent>
  </HawkModalContainer>
</template>
